<template>
  <div>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="5">
        <div>
          <Input placeholder="请输入查询条件" v-model="storePrams.name"/>
        </div>
      </Col>
      <Col span="4">
        <div>
          <DatePicker placeholder="时间查询" v-model="storePrams.create_time" style="width: 100%;"></DatePicker>
        </div>
      </Col>
      <Col span="6">
        <div>
          <Button type="primary" icon="ios-search-outline" @click="searchList">搜索</Button>
        </div>
      </Col>
    </Row>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Button v-if="jurisdictionList.indexOf('admin-agent-add') !== -1" icon="md-add" type="primary" @click="addUser('add')">新增代理商</Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="worlkColumns" :data="data6">
      <template slot-scope="{ row, index }" slot="operation">
        <Button type="info" ghost size="small" style="margin-right: 5px" @click="changeMsg(row)">次数修改</Button>
        <Button v-if="jurisdictionList.indexOf('admin-agent-update') !== -1" type="primary" size="small" style="margin-right: 5px" @click="show('edit',row)">编辑</Button>
        <Button v-if="jurisdictionList.indexOf('admin-agent-del') !== -1" type="error" size="small" @click="remove(index,row)" style="margin-right: 5px">删除</Button>
        <Button v-if="jurisdictionList.indexOf('admin-agent-config') !== -1" size="small" @click="changeConfigure(index,row)">配置权限</Button>
      </template>
      <template slot-scope="{ row }" slot="meituan">
        <Button type="info" @click="mt_amount_btn(row.id)" ghost size="small" style="margin-right: 5px">{{row.mt_amount}}</Button>
      </template>
      <template slot-scope="{ row }" slot="status">
        <div style="display: flex;align-items: center">
          <div style="margin-right: 5px">当前状态({{ row.it_disabled=== 0 ?'已启用':'已禁用' }})</div>
          <Button type="warning" size="small" v-if="row.it_disabled === 0" style="margin-right: 5px" @click="changeOff(row)">禁用</Button>
          <Button type="success" size="small" v-else style="margin-right: 5px" @click="changeOn(row)">启用</Button>
        </div>
      </template>
    </Table>
    <br/>
    <Page :total="total"
          :current="storePrams.page"
          style="text-align:right"
          show-elevator
          show-total
          @on-change="pageChange"
          :page-size="storePrams.limit"/>
    <Modal
        :title="formTitle"
        v-model="showModel"
        :mask-closable="false"
        width="600"
        :footer-hide="true"
        @on-ok="submit"
        @on-cancel="cancel"
        class-name="vertical-center-modal">
      <Form  ref="formItem" :rules="ruleValidate" :model="formItem" :label-width="120" style="width: 500px">
        <FormItem label="账户" prop="account">
          <Input v-model="formItem.account" placeholder="请输入代理商账户"></Input>
        </FormItem>
        <FormItem label="密码" prop="pwd">
          <Input v-model="formItem.pwd" type="password" placeholder="请输入代理商密码"></Input>
        </FormItem>
        <FormItem label="姓名" prop="real_name">
          <Input v-model="formItem.real_name" placeholder="请输入代理商姓名"></Input>
        </FormItem>
        <FormItem label="联系方式" prop="phone">
          <Input v-model="formItem.phone" placeholder="请输入联系方式"></Input>
        </FormItem>
        <FormItem label="有效期">
          <Select v-model="moreChange" placeholder="请选择有效期">
            <Option value='0'>永久</Option>
            <Option value="TIME">选择时间</Option>
          </Select>
        </FormItem>
        <FormItem v-if="moreChange === 'TIME'">
          <DatePicker v-model="formItem.expiration_time" type="date" placeholder="请选择平台有效期" style="width: 380px"></DatePicker>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('formItem')">确认</Button>
          <Button @click="handleReset('formItem')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
    <Modal
        title="配置权限"
        v-model="configure"
        :mask-closable="false"
        width="500"
        @on-ok="submitConfigure"
        @on-cancel="cancelConfigure"
        class-name="vertical-center-modal">
      <Form :model="formConfigure" :label-width="120" style="width: 450px">
        <!-- <FormItem label="云储配置">
          <i-switch v-model="formConfigure.cloud_status" size="large" @on-change="changeFirst">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem> -->
        <FormItem label="OEM配置">
          <i-switch v-model="formConfigure.oem_status" size="large" @on-change="changeThird">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem label="短信配置">
          <i-switch v-model="formConfigure.sms_status" size="large" @on-change="changeSecond">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
      </Form>
    </Modal>
    <Modal
        title="编辑次数"
        v-model="editMessage"
        :mask-closable="false"
        width="600"
        :footer-hide="true"
        class-name="vertical-center-modal">
      <Form ref="messageForm" :rules="ruleMessageForm" :model="messageForm" :label-width="140" style="width: 500px">
        <FormItem label="平台数量" prop="store_num">
          <Input v-model="messageForm.store_num" type="number" placeholder="请输入合同次数"></Input>
        </FormItem>
        <FormItem label="店铺数量" prop="shop_num">
          <Input v-model="messageForm.shop_num" type="number" placeholder="请输入合同次数"></Input>
        </FormItem>
        <FormItem label="短信数量" prop="sms_num">
          <Input v-model="messageForm.sms_num" type="number" placeholder="请输入短信次数"></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleMessage('messageForm')">确认</Button>
          <Button @click="resetMessage('messageForm')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
    <Modal
        :mask-closable='false'
        width="800"
        v-model="balancemodal"
        title="美团金额变动记录">
        <Form :label-width="100">
            <!-- <FormItem label="平台余额：">
              <div style="display:flex">
                <div class="num_Txt">{{platformamount}}</div>
              </div>
            </FormItem> -->
            <FormItem label="当前余额：">
              <div style="display:flex">
                <div class="num_Txt">{{mt_amount}}</div>
                <div style="margin-left:20px"><Button @click="changecli">变动</Button></div>
              </div>
            </FormItem>
            <FormItem label="日期选择：">
              <div style="display:flex">
                <div><DatePicker @on-change="time1" type="daterange" placement="bottom-start" placeholder="点击选择日期" style="width: 200px"></DatePicker></div>
                <div style="margin-left:20px"><Button @click="searchBtn1" class="btn">搜索</Button></div>
              </div>
            </FormItem>
        </Form>
        <Table ref="selection" stripe :columns="balanceColumns" :data="data5">
          <template slot-scope="{ index }" slot="index">
            <div>{{index+1}}</div>
          </template>
          <template slot-scope="{ row }" slot="scene">
            <div v-if="row.scene==1">超级管理员操作</div>
            <div v-if="row.scene==2">代理商/员工操作</div>
          </template>
        </Table>
        <br/>
        <Page :total="total1"
              :current="balancedata.page"
              style="text-align:right"
              show-elevator
              show-total
              @on-change="pageChange1"
              :page-size="balancedata.limit"/>
        <div slot="footer">
            <!-- <Button @click="balancemodal = false" class="btn">关闭</Button> -->
        </div>
    </Modal>
    <Modal
        :mask-closable='false'
        v-model="changemodal"
        title="美团余额变动"
        @on-cancel="changecancel">
        <Form :label-width="100">
            <FormItem label="当前余额：">
              <div>{{mt_amount}}</div>
              <div>
                <RadioGroup v-model="balance.type">
                    <Radio :label="1">增加</Radio>
                    <Radio :label="2">减少</Radio>
                </RadioGroup>
              </div>
            </FormItem>
            <FormItem label="余额变动：">
              <Input v-model="balance.val" placeholder="请输入余额"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button @click="changecancel">取消</Button>
            <Button type="primary" @click="changeadd">确认</Button>
        </div>
    </Modal>
  </div>
</template>
<script>
import {agentList, agentDel, agentAdd, agentUpdate, agentInfo, agentBan,agentSetup,agentUpdateScnum,getMtList,mtHandle} from '../../api/agent'
import moment from "moment";

export default {
  data() {
    var checkphone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!this.isCellPhone(value)) {
        callback(new Error('请输入正确的手机号!'))
      } else {
        callback()
      }
    };
    return {
      worlkColumns: [
        {
          title: '账户',
          width: 160,
          key: 'account'
        },
        {
          title: '姓名',
          width: 160,
          key: 'real_name'
        },
        {
          title: '联系方式',
          width: 160,
          key: 'phone'
        },
        {
          title: '店铺数量',
          width: 180,
          key: 'shop_num'
        },
        {
          title: '平台数量',
          width: 100,
          key: 'store_num'
        },
        {
          title: '短信数量',
          width: 100,
          key: 'sms_num'
        },
        {
          title: '美团余额',
          width: 100,
          slot: 'meituan'
        },
        {
          title: '状态',
          width: 200,
          slot: 'status',
        },
        {
          title: '有效期',
          width: 200,
          render: (h, params) => {
            return h('div', [
              h('div', {
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, params.row.expiration_time)
            ]);
          }
        },
        {
          title: '创建时间',
          key: 'created_at',
          width: 200,
        },
        {
          title: '操作',
          key: 'action',
          width: 300,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      cityList: [
        {
          value: '0',
          label: '姓名、联系方式、所属公司'
        },
        {
          value: '1',
          label: '账户查询'
        }
      ],
      balancemodal:false,
      changemodal:false,
      total1:0,
      mt_amount:0,
      balancedata:{
        id:null,
        page:1,
        limit:5,
        start_time:'',
        end_time:''
      },
      data5:[],
      balanceColumns:[
        {
          title: '#',
          slot: 'index',
          align: 'center',
          width: 60
        },
        {
          title: '时间',
          key: 'created_at',
          align: 'center',
        },
        {
          title: '金额',
          key: 'money',
          align: 'center',
        },
        {
          title: '类型',
          slot: 'scene',
          align: 'center',
        },
        {
          title: '说明',
          key: 'desc',
          align: 'center',
        }
      ],
      balance:{
        id:null,
        val:'',
        type:1,
      },
      model1: '1',
      moreChange:'',
      editMessage:false,
      messageForm: {
        sms_num: '',
        store_num: '',
        shop_num: '',
        user_id:''
      },
      ruleMessageForm:{
        store_num: [
          {required: true, message: '请输入平台数量', trigger: 'blur'}
        ],
        shop_num: [
          {required: true, message: '请输入店铺数量', trigger: 'blur'}
        ],
        sms_num: [
          {required: true, message: '请输入短信数量', trigger: 'blur'}
        ],
      },
      data6: [],
      total:0,
      configure:false,
      storeId:'',
      userId:'',
      showModel: false,
      formItem: {
        account: '',
        pwd: '',
        real_name: '',
        phone: '',
        expiration_time: ''
      },
      ruleValidate: {
        account: [
          { required: true, message: '请输入账户', trigger: 'blur' },
          { type: 'string', min: 3,max:12, message: '公告标题不得少于三个字符超过十二个字符', trigger: 'blur' }
        ],
        // pwd: [
        //   { required: true, message: '请输入密码', trigger: 'blur' }
        // ],
        real_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: checkphone,trigger: 'blur' },
        ],
      },
      formTitle: '新增代理商',
      storePrams: {
        limit: 10,
        page: 1,
        name: '',
        create_time: '',
      },
      formConfigure:{
        cloud_status:false,
        sms_status:false,
        oem_status:false,
        synopsis:''
      },
      jurisdictionList:[]
    }
  },
  created() {
    this.getList()
    this.jurisdictionList = this.$store.state.auth
  },
  mounted() {
  },
  methods: {
    isCellPhone (val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false
      } else {
        return true
      }
    },
    changeMsg(row){
      console.log(row)
      this.messageForm.shop_num=String(row.shop_num)
      this.messageForm.store_num=String(row.store_num)
      this.messageForm.sms_num=String(row.sms_num)
      this.messageForm.user_id=row.id
      this.editMessage =true
    },
    handleMessage(name){
      this.$refs[name].validate((valid) => {
        if (valid) {
          agentUpdateScnum(this.messageForm).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
            this.editMessage = false
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        } else {
          return
        }
      })
    },
    resetMessage(name){
      this.$refs[name].resetFields();
    },
    pageChange1(index){
      this.balancedata.page = index
      this.mtAmountList()
    },
    searchBtn1(){
      this.mtAmountList()
    },
    changecli(){
      this.balance.id = this.balancedata.id
      this.changemodal = true
    },
    time1(e){
      this.balancedata.start_time = e[0]
      this.balancedata.end_time = e[1]
    },
    changecancel(){
      this.balance.type = 1
      this.changemodal = false
    },
    changeadd(){
      mtHandle(this.balance).then(res=>{
        this.mtAmountList()
        this.getList()
        this.changemodal = false
        this.balance.val = ''
        this.balance.type = 1
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    mt_amount_btn(e){
      this.balancedata.id = e
      this.balancemodal = true
      this.mtAmountList()
    },
    mtAmountList(){
      getMtList(this.balancedata).then(res=>{
        this.total1 = res.data.total
        this.data5 = res.data.data
        this.mt_amount = res.data.mt_amount
      })
    },
    getList() {
      agentList(this.storePrams).then(res => {
        this.data6 = res.data.data || []
        this.total = res.data.total
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    changeType(){
      agentSetup(this.storePrams).then(res => {
        console.log(res)
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    searchList() {
      this.storePrams.create_time = moment(this.storePrams.create_time).format('YYYY-MM-DD')
      if (this.storePrams.create_time == 'Invalid date') {
        this.storePrams.create_time = ''
      }
      this.getList()
    },
    show( type,row) {
      if (type === 'edit') {
        this.formTitle = '编辑代理商信息'
      }
      agentInfo({agent_id:row.id}).then(res=>{
        console.log(res.data.expiration_time)
        if(res.data.expiration_time === '永久'){
          this.moreChange = '0'
        }else {
          this.moreChange = 'TIME'
          this.formItem.expiration_time=res.data.expiration_time
        }
        this.formItem.account= res.data.account
        this.formItem.real_name= res.data.real_name
        this.formItem.phone= res.data.phone
        this.formItem.agent_id= res.data.id
      }).catch(res => {
        this.$Message.error(res.msg)
      })
      this.showModel = true
    },
    remove( index,row) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此条代理商，删除后无法恢复！！！',
        onOk: () => {
          agentDel({agent_id: row.id}).then(res => {
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {

        }
      });
    },
    addUser(type) {
      if (type === 'add') {
        this.formTitle = '新增代理商'
      }
     // this.reset()
      this.showModel = true
    },
    changeOff(row){
      if(this.jurisdictionList.indexOf('admin-agent-disabled') !== -1) {
        this.$Modal.confirm({
          title: '禁用提示',
          content: '请问您是否确认禁用此条代理商账户',
          onOk: () => {
            if(row.it_disabled === 0){
              let changeType = 1
              agentBan({user_id:row.id,status:changeType}).then(res=>{
                this.getList()
                this.$Message.success(res.msg)
              }).catch(res=>{
                this.$Message.error(res.msg)
              })
            }
          },
          onCancel: () => {
          }
        });
      }
    },
    changeOn(row){
      if(this.jurisdictionList.indexOf('admin-agent-disabled') !== -1) {
        this.$Modal.confirm({
          title: '启用提示',
          content: '请问您是否确认启用此条代理商账户',
          onOk: () => {
            if(row.it_disabled === 1){
              let changeType = 0
              agentBan({user_id:row.id,status:changeType}).then(res=>{
                this.getList()
                this.$Message.success(res.msg)
              }).catch(res=>{
                this.$Message.error(res.msg)
              })
            }
          },
          onCancel: () => {
          }
        });
      }
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.moreChange === '0') {
            this.formItem.expiration_time = 0
          } else {
            this.formItem.expiration_time = moment(this.formItem.expiration_time).format('YYYY-MM-DD')
          }
          if (this.formItem.agent_id) {
            agentUpdate(this.formItem).then(res => {
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.showModel = false
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          } else {
            agentAdd(this.formItem).then(res => {
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.showModel = false
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          }
        } else {
          this.$Message.error('请输入代理商信息');
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields();
    },
    submit() {

    },
    cancel() {
      this.reset()
    },
    reset(){
      this.formItem.account = ''
      this.formItem.pwd=''
      this.formItem.real_name=''
      this.formItem.phone=''
      this.formItem.expiration_time=''
      this.formItem.agent_id= undefined
    },
    submitConfigure(){

    },
    cancelConfigure(){

    },
    changeConfigure(index,row){
      if(row.cloud_status === 1){
        this.formConfigure.cloud_status = true
      }else {
        this.formConfigure.cloud_status = false
      }
      if(row.sms_status === 1){
        this.formConfigure.sms_status = true
      }else {
        this.formConfigure.sms_status = false
      }
      if(row.oem_status === 1){
        this.formConfigure.oem_status = true
      }else {
        this.formConfigure.oem_status = false
      }
      this.userId = row.id
      this.configure = true
    },
    changeFirst(e){
      let status
      if(e === true){
         status = 1
      }else {
        status = 0
      }
      agentSetup({type:2,status:status,user_id:this.userId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeSecond(e){
      let status
      if(e === true){
        status = 1
      }else {
        status = 0
      }
      agentSetup({type:3,status:status,user_id:this.userId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeThird(e){
      let status
      if(e === true){
        status = 1
      }else {
        status = 0
      }
      agentSetup({type:1,status:status,user_id:this.userId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
  }
}
</script>

<style>
/* .ivu-radio-checked .ivu-radio-inner{
  border-color: #FF761D;
}
.ivu-radio-inner:after{
  background-color: #FF761D;
} */
</style>
